import React, {Component, Suspense} from 'react';
import 'react-notifications/lib/notifications.css';
import {Switch, Route, Redirect} from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import unauthorizedRoutes from "../routes/unauthorizedRoutes";
import {NotificationContainer} from 'react-notifications';
import CognitoAuth from "../CognitoAuth";
import {DEMO} from "../constants";

const MainLayout = Loadable({
  loader: () => import('./layout/MainLayout'),
  loading: Loader
});

class App extends Component {
  render() {
    const menu = unauthorizedRoutes.map((route, index) => {
      return (route.component) ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={props => (
            <route.component {...props} />
          )}/>
      ) : null;
    });
    const isAuthenticated = CognitoAuth.isAuthenticated();
    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader/>}>
            <Switch>
              {menu}
              {isAuthenticated && <Route path="/" component={MainLayout}/>}
              <Redirect to={DEMO.SIGN_IN}/>
            </Switch>
          </Suspense>
        </ScrollToTop>
        <NotificationContainer/>
      </Aux>
    );
  }
}

export default App;
