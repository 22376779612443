import axios from 'axios';
import CognitoAuth from './CognitoAuth';
import {NotificationManager} from 'react-notifications';
import {DEMO} from "./constants";

const baseURL = process.env.REACT_APP_API_URL;

export const apiClient = (history) => {
  const idToken = CognitoAuth.getIdToken(history);
  return axios.create({
    baseURL,
    withCredentials: false, // This is the default
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: idToken ? `Bearer ${idToken}` : undefined,
    },
  })
};
export const handleError = ({error, history}) => {
  console.error('network error: ', error);
  NotificationManager.error(error.response ? (error.response.data.message || error.message) : error.message);
  if (
    (error.request && error.request.status === 401) ||
    (error.response && error.response.status === 401)
  ) {
    history.push(DEMO.SIGN_OUT);
  }
};
