import React from 'react';
import {DEMO} from "../constants";

const LandingPage = React.lazy(() => import('../Pages/LandingPage'));
const SignIn = React.lazy(() => import('../Pages/SignIn'));
const SignUp = React.lazy(() => import('../Pages/SignUp'));
const ForgotPassword = React.lazy(() => import('../Pages/ForgotPassword'));
const SignOut = React.lazy(() => import('../Pages/SignOut'));
const Error404 = React.lazy(() => import('../Pages/Maintenance/Error404'));
const Terms = React.lazy(() => import('../Pages/Maintenance/Terms'));
const PrivacyPolicy = React.lazy(() => import('../Pages/Maintenance/PrivacyPolicy'));
const AcceptablePolicy = React.lazy(() => import('../Pages/Maintenance/AcceptablePolicy'));

const unauthorizedRoutes = [
  { path: DEMO.HOME, exact: true, name: 'Home', component: LandingPage },
  { path: DEMO.SIGN_IN, exact: true, name: 'Signin', component: SignIn },
  { path: DEMO.SIGN_OUT, exact: true, name: 'Signout', component: SignOut },
  { path: DEMO.SIGN_UP, exact: true, name: 'Signout', component: SignUp },
  { path: DEMO.FORGOT_PASSWORD, exact: true, name: 'ForgotPassword', component: ForgotPassword },
  { path: DEMO.TERMS, exact: true, name: 'Terms', component: Terms },
  { path: DEMO.PRIVACYPOLICY, exact: true, name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: DEMO.ACCEPTABLEPOLICY, exact: true, name: 'AcceptablePolicy', component: AcceptablePolicy },
  { path: DEMO.ERROR, exact: true, name: 'Error 404', component: Error404 },
];
export default unauthorizedRoutes;
