import React from 'react';
import { createRoot } from 'react-dom/client';
import {applyMiddleware,createStore} from 'redux';
import {Amplify} from 'aws-amplify';
import {Provider} from 'react-redux';
import logger from 'redux-logger';
import {BrowserRouter} from 'react-router-dom';
import App from './App/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';
import './assets/scss/style.scss';
import {authConfig} from "./CognitoAuth";
import "react-datetime/css/react-datetime.css";

const store = process.env.REACT_APP_WEBSITE_STAGE !== 'production'
  ? createStore(reducer, applyMiddleware(logger))
  : createStore(reducer);

Amplify.configure({Auth: authConfig});

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App/>
    </BrowserRouter>
  </Provider>
);

const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
