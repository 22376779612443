import { Auth } from 'aws-amplify';
import { DEMO } from "./constants";
import { apiClient } from "./apiClient";
import cookie from 'react-cookies'

export const authConfig = {
  userPoolId: 'us-east-1_RpkXkr4EV',
  userPoolWebClientId: 'baqbavti76pfk2pcgshampkds',
  region: 'us-east-1',
  identityPoolId: 'us-east-1:d97027c7-ed14-4d2a-85bc-90f93c8e9270',
  mandatorySignIn: true,
  identityPoolRegion: 'us-east-1',
};

class AuthClass {
  user;
  session;

  async signIn(email, password) {
    this.user = await Auth.signIn(email, password);
    if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return;
    }
    return this.getSession();
  };

  async completePassword(email, newPassword) {
    this.user = await Auth.completeNewPassword(this.user, newPassword);
    return this.getSession();
  };

  async forgotPassword(email) {
    await Auth.forgotPassword(email);
  };

  async forgotPasswordSubmit(email, code, password) {
    return Auth.forgotPasswordSubmit(email, code, password);
  };

  // TODO after MFA integration
  // async confirmSignIn(cognitoUser, code) {
  //   await Auth.confirmSignIn(cognitoUser, code, "SOFTWARE_TOKEN_MFA");
  //   const user = await Auth.currentAuthenticatedUser();
  //   const session = await Auth.currentSession();
  //   localStorage.id_token = session.getIdToken().getJwtToken();
  //   localStorage.access_token = session.getAccessToken().getJwtToken();
  //   delete user.attributes.sub;
  //   const userInfo = {
  //     ...user.attributes,
  //     username: user.username,
  //   };
  //   return userInfo;
  // };

  async signUp({ email, password, given_name, family_name, phone_number }) {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        phone_number,
        given_name,
        family_name,
        address: 'N/A',
      }
    });
  };

  async confirmSignUp(email, code) {
    await Auth.confirmSignUp(email, code);
  };

  isAuthenticated() {
    return !!cookie.load('id_token');
  }

  getIdToken(history) {
    const idToken = cookie.load('id_token');
    if (!idToken && history) {
      history.push(DEMO.SIGN_OUT);
      return;
    }
    return idToken;
  };

  async setGroup(group) {
    await apiClient().patch(`/users/me/${group}`);
    await this.refreshSession();
    this.storeTokens();
  }

  async refreshSession() {
    this.session = await new Promise(((resolve, reject) => {
      this.user.refreshSession(this.session.getRefreshToken(), (err, result) => {
        if (!err) {
          resolve(result);
        } else {
          reject(err);
        }
      });
    }));
  }

  async signOut(history) {
    try {
      if (Auth.user) {
        const user = await Auth.currentAuthenticatedUser();
        if (user.getSignInUserSession()) {
          user.signOut();
        }
      }
      cookie.remove('id_token');
      localStorage.clear();
    } catch (e) {
      console.error(e);
    }
    history.push(DEMO.SIGN_IN);
  }

  storeTokens() {
    const idTokenData = this.session.getIdToken();
    const idToken = idTokenData.getJwtToken();
    const idTokenExpiration = idTokenData.payload.exp;
    const diffMinutes = new Date().getTimezoneOffset();
    cookie.save('id_token', idToken, { expires: new Date((idTokenExpiration - diffMinutes * 60) * 1000) });
  }

  async getSession() {
    this.session = await Auth.currentSession();
    this.storeTokens();
    const groups = this.session.getIdToken().payload['cognito:groups'];
    return { ...this.user.attributes, groups: groups || [] };
  }
}
const auth = new AuthClass();
export default auth;