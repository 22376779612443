export const DEMO = {
  BLANK_LINK: "#",
  HOME: "/",
  SIGN_OUT: "/sign-out",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",
  ERROR: "/error-404",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  PROPERTIES: "/properties",
  REQUESTS: "/requests",
  TENANTS: "/tenants",
  PROFESSIONALS: "/professionals",
  REPORTS: "/reports",
  HOMES: "/homes",
  ITEMS: "/items",
  MESSAGES: "/messages",
  VIEW_PROGRESSION: "/view-progression",
  PROPERTY_DETAIL: "/properties/:propertyId",
  TERMS: "/terms",
  PRIVACYPOLICY: "/privacy-policy",
  ACCEPTABLEPOLICY: "/acceptable-policy"
};
export const UserRoles = {
  PROPERTY_OWNERS: 'PROPERTY_OWNERS',
  TENANTS: 'TENANTS',
  PROFESSIONALS: 'PROFESSIONALS',
};

export const DashboardTitles = {
  PROPERTY_OWNERS: 'Property Owner',
  TENANTS: 'Tenant',
  PROFESSIONALS: 'Professional',
};

export const HomeTypes = {
  APARTMENT: 'APARTMENT',
  SINGLE_FAMILY_HOME: 'SINGLE_FAMILY_HOME',
}

export const HomeTypeLabels = {
  APARTMENT: 'Apartment',
  SINGLE_FAMILY_HOME: 'Single Family Home',
}

export const RepairStatus = {
  REPAIR_REQUESTED: 'REPAIR_REQUESTED', // For tenant
  REPAIR_APPROVED: 'REPAIR_APPROVED', // For PO
  REPAIR_DECLINED: 'REPAIR_DECLINED', // For Professional
  REPAIR_STARTED: 'REPAIR_STARTED', // For Professional
  REPAIR_COMPLETED: 'REPAIR_COMPLETED', // For Professional
  TENANT_CONFIRMED: 'TENANT_CONFIRMED', // For tenant
  PAID: 'PAID', // For PO
  PAYMENT_CONFIRMED: 'PAYMENT_CONFIRMED', // For Professional
  REPAIR_CANCELED: 'REPAIR_CANCELED', // For tenant
}

export const RentalTermLabels = {
  MONTHLY_FIRST_DAY: 'The First Day Every Month',
  MONTHLY_LAST_DAY: 'The Last Day Every Month',
  WEEKLY_FIRST_DAY: 'The First Day Every Week',
  WEEKLY_LAST_DAY: 'The Last Day Every Week',
  BI_WEEKLY_FRIDAY: 'Every Other Friday',
}

export const Frequencies = [
  'MONTHLY',
  'WEEKLY',
  'BI_WEEKLY'
];

export const WhenDues = {
  MONTHLY: ['MONTHLY_FIRST_DAY', 'MONTHLY_LAST_DAY'],
  WEEKLY: ['WEEKLY_FIRST_DAY', 'WEEKLY_LAST_DAY'],
  BI_WEEKLY: ['BI_WEEKLY_FRIDAY']
};

export const ItemNames = [
  'Air conditioner',
  'Air ionizer',
  'Air purifier',
  'Appliance plug',
  'Aroma lamp',
  'Attic fan',
  'Bachelor griller',
  'Bed side lamp',
  'Back boiler',
  'Beverage opener',
  'Blender',
  'Box mangle',
  'Can opener',
  'Ceiling fan',
  'Central vacuum cleaner',
  'Clothes dryer',
  'Clothes iron',
  'Cold-pressed juicer',
  'Combo washer dryer',
  'Dish draining closet',
  'Dishwasher',
  'Domestic robot',
  'Comparison of domestic robots',
  'Drawer dishwasher',
  'Electric water boiler',
  'Evaporative cooler',
  'Exhaust hood',
  'Fan heater',
  'Flame supervision device',
  'Forced-air',
  'Futon dryer',
  'Garbage disposal unit',
  'Garage door',
  'Garage door opener',
  'Gas appliance',
  'Hair dryer',
  'Hair iron',
  'Home server',
  'Humidifier',
  'HVAC',
  'Icebox',
  'Kimchi refrigerator',
  'Light fixture',
  'Light',
  'Micathermic heater',
  'Microwave oven',
  'Mobile charger',
  'Mousetrap',
  'Nightlight',
  'Oil heater',
  'Oven',
  'Patio heater',
  'Paper shredder',
  'Radiator (heating)',
  'Refrigerator',
  'Range Hood',
  'Internet Router',
  'Thermal mass refrigerator',
  'Sewing machine',
  'Space heater',
  'Steam mop',
  'Stove',
  'Sump pump',
  'Television',
  'Tie press',
  'Toaster and toaster ovens',
  'Vacuum cleaner',
  'Manual vacuum cleaner',
  'Robotic vacuum cleaner',
  'Washing machine',
  'Water cooker',
  'Water purifier',
  'Water heater',
  'Solar water heater',
  'Window fan',
  'Waffle iron',
  'Other',
];

export const ItemLocations = {
  'Master bedroom': '',
  'Bedroom': '',
  'Living room': '',
  'Guest bedroom': '',
  'Bathroom': 'numBaths',
  'Kitchen': '',
  'Formal dining room': '',
  'Family room': '',
  'Home office': '',
  'Laundry room': '',
  'Mudroom': '',
  'Garage': 'numGarages',
};

export const REPAIRTYPES = [
  { 
    id: 1, 
    name: 'Plumbing',
    description: 'Issues with the plumbing system',
    options: ['leaky faucets', 'clogged drains', 'broken pipes', 'malfunctioning toilets', 'other']
  },
  { 
    id: 2, 
    name: 'Electrical',
    description: 'Problems with the electrical system',
    options: ['faulty wiring', 'broken light fixtures', 'malfunctioning switches or outlets', 'other']
  },
  { 
    id: 3, 
    name: 'HVAC',
    description: 'Issues with heating, ventilation, and air conditioning systems',
    options: ['malfunctioning thermostats', 'broken air conditioning units', 'clogged air filters', 'other']
  },
  { 
    id: 4, 
    name: 'Structural',
    description: 'Problems with the structural integrity of the house',
    options: ['foundation cracks', 'roof leaks', 'damaged walls or floors', 'other']
  },
  { 
    id: 5, 
    name: 'Appliance repair',
    description: 'Issues with home appliances',
    options: ['not working/no power', 'performance issue', 'error codes/malfunctions', 'preventive maintenance', 'other']
  },
  { 
    id: 6, 
    name: 'Landscaping',
    description: 'Issues with the exterior of the house',
    options: ['damaged fencing', 'broken sprinklers', 'overgrown trees and bushes', 'other']
  },
  { 
    id: 7, 
    name: 'Painting and finishes',
    description: 'Problems with the aesthetic aspects of the house',
    options: ['peeling paint', 'damaged wallpaper', 'worn-out flooring', 'other']
  },
  { 
    id: 8, 
    name: 'Pest control',
    description: 'Issues with pests such as rodents',
    options: ['termites', 'ants', 'bed bugs', 'other']
  },
  { 
    id: 9, 
    name: 'Safety and security',
    description: 'Issues related to safety and security',
    options: ['malfunctioning smoke detectors', 'broken locks', 'damaged windows', 'damaged door', 'malfunctioning garage door/opener', 'other']
  },
]

export const VERSION_KEY = "0.0.1"